import { graphql } from 'gatsby'
import React from 'react'
import BannerRegister from '../components/molecules/bannerRegister/bannerRegister'
import NuestraMarcaContent from '../components/organisms/nuestraMarcaContent/nuestraMarcaContent'
import SEO from '../shared/components/seo'
import Layout from '../shared/components/organisms/layout/layout'
import useMobileMode from '../shared/hooks/useMobileMode'

const NuestraMarca = props => {
	const { allContentfulTenaNuestraMarca } = props.data
	const { isMobile, currentPage } = useMobileMode()

	const banner = allContentfulTenaNuestraMarca.nodes[0].banner

	const bannerInfo = {
		images: banner,
		title: banner.nombre,
		subtitle: banner.descripcionImagen,
	}

	return (
		<Layout title={allContentfulTenaNuestraMarca.nodes[0].metaTitulo} isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title={allContentfulTenaNuestraMarca.nodes[0].metaTitulo}
				description={
					allContentfulTenaNuestraMarca.nodes[0].metaDescripcion.metaDescripcion
				}
				image={banner?.bannerDesktop?.fluid?.src}
				slug={'/nuestra-marca/'}
				lang={props.pageContext.language}
			/>
			<BannerRegister
				bannerInfo={bannerInfo}
				isMobile={isMobile}
				slug={props.data.allContentfulTenaNuestraMarca.slug}
			/>
			<NuestraMarcaContent
				isMobile={isMobile}
				array={allContentfulTenaNuestraMarca.nodes[0]}
			/>
		</Layout>
	)
}

export const query = graphql`
	query GET_NUESTRA_MARCA($language: String!) {
		allContentfulTenaNuestraMarca(filter: { node_locale: { eq: $language } }) {
			nodes {
				slug
				banner {
					nombre
					descripcionImagen
					bannerDesktop {
						id
						description
						title
						fluid(maxWidth: 1800, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					bannerMobile {
						id
						description
						title
						fluid(maxWidth: 600, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
				}
				tarjetaUnoTitulo
				tarjetaUnoSubtitulo
				tarjetaUnoImagen {
					file {
						url
					}
					title
					description
				}
				tarjetaUnoDescripcion {
					json
				}
				tarjetaDosTitulo
				tarjetaDosSubtitulo
				tarjetaDosImagenDescripcion {
					json
				}
				tarjetaDosDescripcion {
					json
				}
				tarjetaDosImagen {
					file {
						url
					}
					title
					description
				}
				tarjetaDosBoton
				metaDescripcion {
					metaDescripcion
				}
				metaTitulo
			}
		}
	}
`

export default NuestraMarca
