import React from 'react'
import './cardMark.scss'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const CardMark = props => {
	return (
		<div className='f-card-mark'>
			<div className='f-card-mark-header'>
				<div
					className='f-mark'
					style={{ backgroundColor: props.colorMark }}
				></div>
				<h2 className='f-title'>
					{props.title}
					<b className='f-subtitle'>{props.subtitle}</b>
				</h2>
			</div>

			<div className='f-card-mark-body'>
				<div className='f-card-mark-left'>
					<div className='f-description'>
						{props.description &&
							documentToReactComponents(props.description.json)}
					</div>
				</div>
				<div className='f-card-mark-right'>
					<div
						className='f-card-separator'
						style={{ opacity: props.showSeparator ? 0.3 : 0 }}
					></div>
					<div className='f-card-img-description'>
						{props.imagesDescription &&
							documentToReactComponents(props.imagesDescription.json)}
					</div>
					<img
						className='f-card-mark-img'
						src={props.images[props.isMobile ? 1 : 0].file.url}
						title={props.images[props.isMobile ? 1 : 0].title}
						alt={props.images[props.isMobile ? 1 : 0].description}
					/>
					{props.showButton && (
						<a
							className='card-mark-btn'
							rel='noreferrer noopener'
							href='http://www.grupofamilia.com.co'
							target='_blank'
						>
							<button>
								Conoce más de Grupo Familia en <b> www.grupofamilia.com.co </b>
							</button>
						</a>
					)}
				</div>
			</div>
		</div>
	)
}

export default CardMark
