import React from 'react'
import './nuestraMarcaContent.scss'
import CardMark from './../../molecules/cardMark/cardMark'

const NuestraMarcaContent = props => {
	return (
		<section className='f-nuestra-marca-cont'>
			<CardMark
				isMobile={props.isMobile}
				colorMark={'#62c224'}
				title={props.array.tarjetaUnoTitulo}
				subtitle={props.array.tarjetaUnoSubtitulo}
				description={props.array.tarjetaUnoDescripcion}
				images={props.array.tarjetaUnoImagen}
				imagesDescription={null}
				showSeparator={false}
				showButton={false}
			/>
			<CardMark
				isMobile={props.isMobile}
				colorMark={'#d21a67'}
				title={props.array.tarjetaDosTitulo}
				subtitle={props.array.tarjetaDosSubtitulo}
				description={props.array.tarjetaDosDescripcion}
				images={props.array.tarjetaDosImagen}
				imagesDescription={props.array.tarjetaDosImagenDescripcion}
				showSeparator={true}
				showButton={true}
			/>
		</section>
	)
}

export default NuestraMarcaContent
